<template>
  <header>
    <router-link :to="{ name: 'Home' }" class="home-link"
      >ritaf &times; {{ area }}</router-link
    >
  </header>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    area: String,
  },
})
export default class Header extends Vue {
  area!: string;
}
</script>

<template>
  <div class="showcase">
    <div class="photo">
      <img src="../assets/img/rita.jpg" alt="portrait of rita" />
    </div>
    <div class="intro">
      <p class="intro-text">Hi, I'm</p>
      <h1 class="intro-title">Rita Freitas</h1>
      <p class="intro-description">technologist & humanist</p>
    </div>
  </div>
  <div class="aboutme-wrapper">
    <h2 class="aboutme-title">About me</h2>
    <div class="aboutme-content">
      <p class="aboutme-description">
        I’m a multidisciplinary creator with experience in design and
        <span style="white-space: nowrap">full-stack</span>
        development. I'm passionate about solving challenging problems and
        building solutions to improve people's lives.<br /><br />
        I am currently based in Stockholm, working as a UX Enginner at
        <a
          href="https://www.vizrt.com/"
          target="_blank"
          rel="noopener noreferrer"
          >Vizrt</a
        >
        with a focus on design systems and developer experience.
      </p>
    </div>
    <div class="aboutme-learnmore">
      <a
        href="/pdf/CV_RitaFreitas.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="learnmore-link"
        >learn more about me and my path on my résumé<img
          src="../assets/img/new_win.svg"
          alt="open in new window"
      /></a>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class AboutMe extends Vue {}
</script>

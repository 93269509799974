<template>
  <head>
    <title>r i t a f &times; w o r k</title>
  </head>
  <Header :area="area" />
  <router-view />
  <Footer />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  area = "net";
}
</script>

<style lang="css">
@import "@/assets/css/style.css";
</style>

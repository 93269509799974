import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createVNode(_component_router_link, {
      to: { name: 'Home' },
      class: "home-link"
    }, {
      default: _withCtx(() => [
        _createTextVNode("ritaf × " + _toDisplayString(_ctx.area), 1)
      ]),
      _: 1
    })
  ]))
}
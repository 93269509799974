<template>
  <div class="projectshowcase">
    <div class="project-photo">
      <img
        src="../assets/img/otto/header.png"
        alt="mockup of telegram chatbot"
      />
    </div>
    <div class="project-title">
      <h1 class="projectdetail-title">OTTO THE OCTOBOT</h1>
    </div>
  </div>

  <div class="project-detail">
    <div class="context-wrapper">
      <div class="context-container">
        <h2 class="context-title">Context</h2>
        <p class="context-content">
          "An HCI Perspective on Chatbots" course at University of Bremen<br />
          February 2020
        </p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Team</h2>
        <p class="context-content">Group work (3 people in total)</p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Main tasks</h2>
        <p class="context-content">Research, implementation and user testing</p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Toolbox</h2>
        <p class="context-content">Python, Telegram, Heroku, Excel</p>
      </div>
    </div>

    <h2 class="challenge-title">Challenge</h2>
    <div class="challenge-content">
      <p>
        Evaluate the impact of emotional expression on chatbot engagement and
        user behaviour change.
      </p>
    </div>

    <h2 class="solution-title">Solution</h2>
    <div class="solution-content">
      <p>
        Telegram chatbot that informs on the negative impacts of plastic
        consumption, evaluates current habits, and gives suggestions on how to
        change harmful habits while expressing emotions through the use of
        animated stickers.
      </p>
    </div>

    <h2 class="process-title">Process</h2>

    <div class="process-content">
      <h3>Development</h3>

      <h4>The product</h4>
      <p>
        We started our work by finding a meaningful subject that can act as the
        starting point for our chatbot. Since plastic consumption and pollution
        are topics that people are growing worried about, we considered that a
        chatbot that tackles these matters can successfully engage people in
        conversation.
      </p>
      <p>
        Given our research question for this project, we decided to create our
        chatbot based on a simple chatscript. It was important for us to offer a
        similar experience to all users and reduce the likelihood that the user
        will feel misunderstood by the chatbot. While designing the chatscript,
        we focused on providing information as well as reacting to user input.
        Throughout the dialogue, we incorporated emotional cues in the form of
        stickers.
      </p>

      <h4>Character design</h4>
      <p>
        The choice of the octopus as our character comes from the mutual
        sensibility towards aquatic animals which are on the front line when it
        comes to experiencing the negative effects of plastic pollution. Some of
        its main features are its tentacles and ability to change colour when
        experiencing different emotional states. Elements inspired by cartoon
        iconography (stars and zigzags), the movement of the tentacles and poses
        (rubbing its chin, raising its eyebrow while thinking) were applied to
        convey human-like emotions through the non-human character.
      </p>
      <p>
        To evaluate the impact of emotional expression, we decided to create 3
        versions of our chatbot: one with static stickers, one with animated
        stickers and one without any stickers.
      </p>

      <img
        src="../assets/img/otto/character.gif"
        alt="the stickers created for the character"
      />
      <h4>Implementation</h4>
      <p>
        The chatbot was implemented using a Python library named
        <a
          href="https://python-telegram-bot.org/"
          target="_blank"
          rel="noopener noreferrer"
          >python-telegram-bot</a
        >
        that offers an interface for the Telegram Bot API. We chose Telegram as
        a platform for the chatbot as it provided rich formatting options,
        "stickers" that could be used for the character representation, as well
        as a simple way to give the user predefined answer options.
      </p>
      <div class="video-container">
        <video width="65%" controls>
          <source src="../assets/img/otto/telegram.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <p>
        To test different versions of the chatbot, we created two sets of
        stickers, animated and static, by uploading each file to Telegram using
        the Stickers bot. When finalised, the application was deployed via
        Heroku and made available to anyone who had access to the link. All 3
        versions of Otto are available through the same chatbot and at the start
        of each launch, one of the versions is randomly selected.
      </p>

      <h4>My contributions</h4>
      <p>
        We worked very closely as a team during the development of this project,
        and everyone actively participated in each step's decisions. My main
        responsibility lied on the implementation and deployment of the chatbot.
        This experience allowed me to help other colleagues in this course to
        implement their own Telegram chatbots.
      </p>

      <h3>User testing</h3>
      <p>
        There were 30 participants in our study. They were equally and randomly
        assigned to each version of the chatbot (without stickers, with static
        stickers, with animated stickers). At the end of the conversation, the
        chatbot would send a survey for the participant to complete. The survey
        asked about the level of engagement with the chatbot, how the
        participants perceived its capabilities, how the stickers (for the
        respective versions) impacted the conversation and the willingness to
        change behaviour. To compare the three different versions of the chatbot
        we performed a single factor ANOVA on the answers for each question.
      </p>
      <p>The main findings are:</p>
      <p>
        <strong>Any stickers is always better than no stickers.</strong>
        Participants of the two versions that included stickers to portray
        Otto's emotional expression found the chatbot to be more natural,
        engaging, and expressive than compared to the ones who were using the
        version without stickers.
      </p>
      <p>
        <strong>Stickers improve immersion and meaning.</strong> Participants of
        the groups using stickers reported feeling more immersed in the
        conversation, as opposed to the ones using the version without any
        visual cues. They were also able to better the interpret the meaning of
        the text and empathised with the character.
      </p>
      <p>
        <strong>The cost of animation is not worth it.</strong> Animating
        stickers is a costly task with many restrictions and when comparing the
        two versions of the stickers, the chatbot version with animated stickers
        was not rated significantly better than the one with static stickers.
      </p>
    </div>

    <h2 class="results-title">Results</h2>

    <div class="results-content">
      <p>
        We created a simple chatbot that can help people learn more about their
        plastic consumption and give tips on how they can improve their habits.
      </p>
      <p>
        This project confirmed how important it is to have teammates with
        different skill sets and backgrounds where each can contribute with
        different perspectives. This allowed us to learn a lot from one another
        while successfully building a new product.
      </p>
      <p>
        Our research was reviewed with great approval and evaluated with a grade
        of 1.0 (equivalent to an A).
      </p>
    </div>
  </div>
  <div class="nav-container">
    <div class="projects-nav">
      <div class="previous-project">
        <router-link :to="{ name: 'Apart' }"
          ><div class="arrow-left"></div>
          <p>Previous project</p></router-link
        >
      </div>
      <div class="next-project">
        <router-link :to="{ name: 'Home' }"
          ><p>Home</p>
          <div class="arrow-right"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";
export default class Otto extends Vue {}
</script>

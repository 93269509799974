<template>
  <div class="projectshowcase">
    <div class="project-photo">
      <img src="../assets/img/apart/header.png" alt="mockups of a-part app" />
    </div>
    <div class="project-title">
      <h1 class="projectdetail-title">A-PART</h1>
    </div>
  </div>

  <div class="project-detail">
    <div class="context-wrapper">
      <div class="context-container">
        <h2 class="context-title">Context</h2>
        <p class="context-content">
          Master Thesis at University of Bremen<br />
          January 2021
        </p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Team</h2>
        <p class="context-content">Individual work</p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Main tasks</h2>
        <p class="context-content">
          Project management, research, user stories and flows, moodboard, UX/UI
          design, prototyping
        </p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Toolbox</h2>
        <p class="context-content">Figma, LateX, Excel, Gitlab</p>
      </div>
    </div>

    <h2 class="challenge-title">Challenge</h2>
    <div class="challenge-content">
      <p>
        Investigate the habits and consequences of the use of mobile devices and
        mobile messaging apps. Explore new solutions and design approaches that
        empower users to care for their mental well-being and personal
        relationships.
      </p>
    </div>

    <h2 class="solution-title">Solution</h2>
    <div class="solution-content">
      <p>
        Definition of the concept and principles of slow messaging supported by
        digital mindfulness and slow design principles. This definition provides
        designers with a tool to ideate and validate future messaging apps'
        designs and is complemented with the proposal of a novel messaging app
        prototype named A-PART, which illustrates a potential slow messaging
        product.
      </p>
    </div>

    <h2 class="process-title">Process</h2>

    <div class="process-content">
      <h3>Research</h3>
      <h4>Understanding current issues</h4>
      <p>
        To understand the impacts of mobile devices and messaging apps on
        people's mental well-being I started an extensive research on existing
        work. The main issues I found include stress and anxiety, which, among
        many reasons, can be caused by the disruptive nature of mobile apps, by
        an overflowing of information and over-stimulation of the brain or by
        the social pressure to be "always on".
      </p>

      <h4>Study</h4>
      <p>
        With the intention of analysing in more detail how mobile device users
        connect through messaging apps and how it affects their relationships, I
        conducted a study where information about 134 participants was collected
        through an online survey. This study confirmed the importance of
        messaging apps to mobile device users while providing a detailed insight
        into how people interact through them. The results demonstrated current
        mobile messaging apps do not completely contribute to meaningful
        interactions and revealed aspects that call for improvement.
      </p>

      <h4>Finding existing solutions</h4>
      <p>
        After compiling information on the problems that can arise from using
        messaging apps, I started an investigation on approaches that could
        tackle the harmful features of these apps. I searched for example
        projects and explored how topics such as mindfulness or slow movements
        could support new messaging solutions.
      </p>

      <h4>Digital mindfulness</h4>
      <p>
        Mindfulness is a state of mind of being attentive to the present moment
        and engaging in active, objective, and non-judgemental experiences,
        which positively contributes to mental health. In my search for how
        aspects of mindfulness practice could impact the design of digital
        products, I found
        <a
          href="https://www.designingmindfulness.com/"
          target="_blank"
          rel="noopener noreferrer"
          >Designing Mindfulness</a
        >, a project that offers principles to bring mindfulness to digital
        products. These principles aim to support designers in making decisions
        that prioritise the users' well-being.
      </p>

      <h4>Slow design</h4>
      <p>
        I learned about slow technology and slow design where I found about the
        importance of the time dimension when designing objects to consider how
        they are used over time and how we live with them, along with the need
        to shift from focus on task efficiency to creating spaces for
        reflection. The theory on slow design also presents guiding principles
        to support designers to reflect on their processes and create new
        products that contribute to a more sustainable future.
      </p>

      <h3>Development</h3>
      <p>
        In my research for projects, I found there is an interest in developing
        alternative messaging solutions, under the concept of "slow messaging" –
        inspired by slow design. However, these mostly focused in the delayed or
        gradual delivery of messages and there were no guidelines for other
        designers to develop their own “slow messaging” products. I decided to
        propose a definition for slow messaging along with a set of guiding
        principles. Beyond creating a solution, my goal was to help others
        create apps with the same ideas in mind as I believe there isn’t only
        one possible solution.
      </p>
      <h4>Slow messaging</h4>
      <p>
        Inspired by digital mindfulness and slow design principles and taking
        the current issues of messaging apps as a starting point, I categorised
        slow messaging in contrast with the worst scenario of "fast messaging"
        by distinctive terms. The defining terms of fast messaging highlight the
        issues that can arise from messaging apps that would offer a negative
        experience to its users. On the opposite side, slow messaging aims to
        counter the negative experience and focus on the well-being of the users
        and their relationships.
      </p>
      <img
        src="../assets/img/apart/table.png"
        alt="fast and slow messaging terms"
      />
      <p>
        My next step was to detail how each term contributes to creating an
        experience that prioritises well-being and meaningful connections
        through a short description. These descriptions can be used by designers
        to validate the experiences provided by a product and reflect on its
        potential impact.
      </p>
      <h4>Product design</h4>
      <p>
        With the slow messaging guiding principles in mind, I started
        brainstorming ideas for alternatives that defy and break the norms of
        current messaging apps. I focused on common features, such as accessing
        an app, receiving, opening, and reading messages, finding contacts, each
        at a time and started sketching a potential slow messaging product.
      </p>
      <p>
        The result of iterative work on conceptualising this slow messaging
        product was the A-PART app, which takes its name from wordplay from
        "apart" – as in being separate – and being "a part" of a connection.
      </p>
      <h4>Product features</h4>
      <p>
        One of A-PART's key feature is its timeslot-based access and
        notifications. The timeslot-based access or "access windows" was mainly
        motivated by the "opportune" principle and empowers users to actively
        decide at which moments of their day they access A-PART, so that they
        mindfully engage with the app. It also takes inspiration from the
        traditional mail system, where the postman will only come at certain
        times of the day to deliver letters. When a user of the A-PART app is
        proactive in the choice of their personal "access windows", they are
        consequently deciding the intervals for which they approve the reception
        of notifications, avoiding interruptions during important moments.
      </p>
      <div class="sidebyside-video-container">
        <video width="49%" controls>
          <source
            src="../assets/img/apart/createaccount.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <video width="49%" controls>
          <source src="../assets/img/apart/blocked.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <p>
        The A-PART app focuses solely on one-to-one connections. This feature of
        A-PART is heavily influenced by the "personal" and "meaningful"
        principles as it aims to create a safe space for intimate conversations
        and consequently strengthening relationships. The creation of a new
        connection actively involves the two sides in the process, as suggested
        by the "collaborative" slow messaging principle. Only when both have
        completed the operation of creating a new connection with the correct
        passwords, they can interact with each other. A-PART presents a list of
        all the existing connections, where they are displayed one at a time,
        giving users the opportunity to focus on each of their connections
        individually. It's from this list of connections that the users access
        messages for each person.
      </p>
      <div class="sidebyside-video-container">
        <video width="49%" controls>
          <source
            src="../assets/img/apart/creatingconnection.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <video width="49%" controls>
          <source src="../assets/img/apart/connections.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <p>
        For the process of opening and reading messages I created the concept of
        a "digital stamp", also inspired by traditional mail. It allows senders
        to personalise messages and offers the space for a "reflective" moment,
        so that receivers can enter the interaction in a more conscious state of
        mind. It also focuses on providing a "meaningful" and "collaborative"
        experience.
      </p>
      <div class="sidebyside-video-container">
        <video width="49%" controls>
          <source src="../assets/img/apart/opening.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video width="49%" controls>
          <source src="../assets/img/apart/messages.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <h2 class="results-title">Results</h2>

    <div class="results-content">
      <p>
        My research for this project showed the current issues of messaging apps
        and mobile devices and how users perceive them. I defined guiding
        principles for a slow messaging theory and built an interactive
        prototype of a possible solution as I found there was a lack of
        proposals on entire products focused on prioritising the users' mental
        health and relationships, as well as an absence of tools to aid
        designers in creating messaging apps with this same focus.
      </p>
      <p>
        For my master thesis I had the opportunity to independently work on a
        topic of my choice and the project was born out of my personal interest
        in the practice of mindfulness and slow movements. This experience
        showed me how useful such guiding principles can be to help designers
        create thoughtful products that care for its user’s well-being and was
        very enriching to my design process.
      </p>
      <p>
        My master thesis was received with a lot of interest and evaluated with
        a grade of 1.3 (equivalent to an A).
      </p>
    </div>
  </div>
  <div class="nav-container">
    <div class="projects-nav">
      <div class="previous-project">
        <router-link :to="{ name: 'Cassiopeia' }"
          ><div class="arrow-left"></div>
          <p>Previous project</p></router-link
        >
      </div>
      <div class="next-project">
        <router-link :to="{ name: 'Otto' }"
          ><p>Next project</p>
          <div class="arrow-right"></div
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";
export default class Apart extends Vue {}
</script>

<template>
  <div class="projects-wrapper">
    <h2 class="projects-title">Work</h2>

    <div class="projects">
      <div id="project-1" class="project-content">
        <div class="project-description">
          <router-link :to="{ name: 'Cassiopeia' }"
            ><p>
              Intelligent voice-activated lab assistant for extraterrestrial
              missions
            </p>
            <p class="arrow-right"></p
          ></router-link>
        </div>
      </div>

      <div id="project-2" class="project-content">
        <div class="project-description">
          <router-link :to="{ name: 'Apart' }"
            ><p>
              Mobile instant messaging app to build meaningful connections with
              others
            </p>
            <p class="arrow-right"></p
          ></router-link>
        </div>
      </div>

      <div id="project-3" class="project-content">
        <div class="project-description">
          <router-link :to="{ name: 'Otto' }"
            ><p>
              Telegram chatbot to bring awareness to plastic consumption and
              support new habits
            </p>
            <p class="arrow-right"></p
          ></router-link>
        </div>
      </div>

      <div class="projects-learnmore">
        <a
          href="https://digitalmedia-bremen.de/student/ana-rita-freitas/"
          target="_blank"
          rel="noopener noreferrer"
          class="learnmore-link"
          >see additional projects featured on my Master's program website
          <img src="../assets/img/new_win.svg" alt="open in new window" />
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class ProjectsList extends Vue {}
</script>

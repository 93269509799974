import { createRouter, createWebHashHistory } from "vue-router";

// Import your components
import Home from "@/components/Home.vue";
import Otto from "@/components/Otto.vue";
import Apart from "@/components/Apart.vue";
import Cassiopeia from "@/components/Cassiopeia.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/work/otto/",
    name: "Otto",
    component: Otto,
  },
  {
    path: "/work/apart",
    name: "Apart",
    component: Apart,
  },
  {
    path: "/work/cassiopeia",
    name: "Cassiopeia",
    component: Cassiopeia,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutMe = _resolveComponent("AboutMe")!
  const _component_Work = _resolveComponent("Work")!

  return (_openBlock(), _createElementBlock("body", null, [
    _createVNode(_component_AboutMe),
    _createVNode(_component_Work)
  ]))
}
<template>
  <body>
    <AboutMe />
    <Work />
  </body>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import AboutMe from "./AboutMe.vue";
import Work from "./Work.vue";

@Options({
  components: {
    AboutMe,
    Work,
  },
})
export default class Home extends Vue {}
</script>

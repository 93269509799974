<template>
  <div class="projectshowcase">
    <div class="project-photo">
      <img
        src="../assets/img/cassiopeia/header.png"
        alt="mockups of cassiopeia"
      />
    </div>
    <div class="project-title">
      <h1 class="projectdetail-title">CASSIOPEIA</h1>
    </div>
  </div>

  <div class="project-detail">
    <div class="context-wrapper">
      <div class="context-container">
        <h2 class="context-title">Context</h2>
        <p class="context-content">
          Master Project at University of Bremen<br />
          March 2020
        </p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Team</h2>
        <p class="context-content">Group work (6 people in total)</p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Main tasks</h2>
        <p class="context-content">
          Project management, research, user stories and flows, moodboard, UX/UI
          design, implementation and user testing
        </p>
      </div>
      <div class="context-container">
        <h2 class="context-title">Toolbox</h2>
        <p class="context-content">
          Gitlab, Slack, Scrum, Figma, Python, SNIPS, Django REST Framework,
          Raspberry Pi, SQLite
        </p>
      </div>
    </div>

    <h2 class="challenge-title">Challenge</h2>
    <div class="challenge-content">
      <p>
        Design and build a tool to support astronauts in long-term
        extraterrestrial missions in collaboration with the MaMBA project.
      </p>
    </div>

    <h2 class="solution-title">Solution</h2>
    <div class="solution-content">
      <p>
        Voice-activated system to support astronauts in a laboratory environment
        by allowing them to select scientific experiments, find the necessary
        equipment and reagents, and navigate through the instructions of a
        procedure. CASSIOPEIA includes a graphical interface to provide
        additional information on the procedure and subtle visual cues of the
        available commands that are accessible at each stage of the interaction.
      </p>
    </div>

    <h2 class="process-title">Process</h2>

    <div class="process-content">
      <h3>Research</h3>
      <h4>Understanding needs</h4>
      <p>
        To understand the status of the project and needs, I personally lead an
        interview with the responsible for the MaMBA project. We found that the
        laboratory module was the only available prototype and learned about the
        life of a crew member living in such a habitat, such as their routines,
        which tasks they perform, and which objects are present in the
        laboratory.
      </p>
      <img
        src="../assets/img/cassiopeia/persona.png"
        alt="persona of scientist crewmember"
      />
      <h4>The product</h4>
      <p>
        During brainstorming sessions with the team, we decided to focus on
        supporting tasks in the laboratory through a voice-activated solution to
        facilitate interaction and include a complementary graphical interface
        for further details.
      </p>
      <p>
        Our next step was to find tools that could make this project come to
        life, as well as other related work on the topic, which we shared among
        the team through short presentations and workshops. I focused on
        researching design guidelines and possible issues that arise from the
        interaction with voice assistants. One of the issues studies found was
        that people were not sure what they could say to an assistant which
        supported our decision to include the graphical interface in our system.
      </p>
      <p>
        After this step, I started detailing user stories in a product backlog
        by taking the output from our brainstorming sessions as a starting
        point, while incorporating what we had learned from our research. As a
        team, we were able to prioritise these user stories and define the scope
        of the product we were going to build in the following weeks.
      </p>
      <img
        src="../assets/img/cassiopeia/userstory.png"
        alt="example of a user story"
      />
      <h3>Development</h3>
      <h4>Organisation</h4>
      <p>
        For this stage, we organised our work using the Scrum framework. Not
        everyone was familiarised with it, but another team colleague who also
        had previous experience with Scrum, supported me throughout the entire
        process and in helping the others to learn about it. It was not an easy
        task as everyone had different schedules. We relied on Gitlab to
        organise our sprints and Slack to maintain an active communication and
        have our dailies. We had a total of seven 2-week sprints, which included
        user testing. At the end of each sprint, we demonstrated CASSIOPEIA to
        the project's supervisor.
      </p>
      <h4>Technical architecture</h4>
      <p>
        Our system's architecture is composed by the speech-recognition and
        text-to-speech modules, the command execution module, database, and Web
        API, and finally, the front-end web application. The entire system is
        installed on a Raspberry Pi and fully works offline.
      </p>
      <img
        src="../assets/img/cassiopeia/architecture.png"
        alt="representation of technical architecture"
      />

      <p>
        CASSIOPEIA processes audio inputs using a natural language processing
        engine named
        <a href="https://snips.ai" target="_blank" rel="noopener noreferrer"
          >SNIPS</a
        >
        (now acquired and discontinued by Sonos). This platform allowed us to
        create custom intents and train a speech model by submitting example
        sentences. Through this model, the system is able to detect intents from
        voice commands and once an intent is recognised, CASSIOPEIA runs the
        corresponding applet from the command execution module. The connection
        to the other services (e.g. Web API, front-end) is programmatically
        initiated from the command code when necessary.
      </p>
      <h4>Conversation flow</h4>
      <p>
        One of our biggest challenges was the conversation flow. Through
        roleplaying experimentation, we organised it into different states to
        better find the context of the conversation.
      </p>
      <img
        src="../assets/img/cassiopeia/conversationflow.png"
        alt="diagram of conversation flow"
      />
      <p>
        We also defined a set of guidelines for the assistant's output to
        maintain a consistent dialog.
      </p>
      <p>
        <strong>Command & context confirmation.</strong> The assistant confirms
        the command that was received giving the user the opportunity to verify
        that their intention was well recognised and that they are in the
        correct context.
      </p>
      <p>
        <strong>Command suggestions.</strong> The assistant helps users with
        which commands they can perform in each context and how to request them.
      </p>
      <p>
        <strong>Content.</strong> The assistant provides the content for the
        requested command. This is provided at the end to allow for better
        memorisation.
      </p>
      <h4>My contributions</h4>
      <p>
        My contributions focused on the design of the system's architecture, in
        the development of the conversation flow and speech model training, in
        the implementation of the command execution model and Web API, as well
        as in the management of each sprint.
      </p>
      <h3>User testing</h3>
      <p>
        To examine CASSIOPEIA, we conducted a between-subject study with 10
        participants in the laboratory module to compare the voice-only
        interface, where there is only a voice output, with the "hybrid"
        interface, where there is an additional graphical output. We evaluated
        its performance through the usability questionnaires SUS and PSSUQ and
        an open interview to further analyse the participants' expectations and
        behaviours.
      </p>
      <p>The main takeaways of our study are:</p>
      <p>
        <strong>A "hybrid" interface increases efficiency.</strong>
        Participants using the voice-only interface took significantly longer to
        conduct an experiment from start to finish and had to request
        information from the system significantly more often, as compared with
        the "hybrid" version.
      </p>
      <p>
        <strong>A "hybrid" interface welcomes inexperienced users.</strong>
        Participants with the lowest experience on voice assistants using the
        voice-only interface had the most trouble interacting with CASSIOPEIA,
        as opposed to the participants on the same level using the "hybrid"
        version.
      </p>
      <p>
        <strong>Suggestions can limit perception of available commands.</strong>
        Many participants took the suggestions as the only way they could word
        their commands, despite the system being able to understand multiple
        expressions for each command.
      </p>
    </div>

    <h2 class="results-title">Results</h2>

    <div class="results-content">
      <p>
        We built a working voice assistant to guide scientists through complex
        experiment protocols in an extraterrestrial environment. It functions
        entirely offline and can easily be moved anywhere, as well as fully
        customised regarding its output.
      </p>
      <p>
        Working in this project taught me a lot about leading a team and
        understanding how to manage everyone's efforts to deliver the highest
        quality product. It was also a great learning experience on how to
        design conversational solutions and on how to perform in-person studies
        in a real-world environment. Our agile development process was essential
        and allowed us to adapt under unpredictable circumstances, such as the
        discontinuation of the natural language processing engine we were using
        before the conclusion of the project. I was able to re-prioritise our
        backlog so that we could implement our product according to the initial
        plan.
      </p>
      <p>
        Our work was very well-received by our supervisor and the MaMBA project
        responsible, granting us a grade of 1.0 (equivalent to an A).
      </p>
    </div>
  </div>
  <div class="nav-container">
    <div class="projects-nav">
      <div class="previous-project">
        <router-link :to="{ name: 'Home' }"
          ><div class="arrow-left"></div>
          <p>Home</p></router-link
        >
      </div>
      <div class="next-project">
        <router-link :to="{ name: 'Apart' }"
          ><p>Next project</p>
          <div class="arrow-right"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class Cassiopeia extends Vue {}
</script>

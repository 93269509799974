<template>
  <footer>
    <div class="footer-container">
      <p>
        If you think I'd be a good fit for your team, <br />
        <a href="mailto:hello@ritafx.net">let's get in touch</a>.
      </p>
    </div>
  </footer>
</template>
<script lang="ts">
import { Vue } from "vue-class-component";

export default class Footer extends Vue {}
</script>
